import { Component, OnInit, Input } from "@angular/core"
import {
    Estadisticas,
    EvaluacionMultiple,
    EvaluacionMultipleInstancia,
    Evaluacion,
    EvaluacionTiempos,
    EvaluacionInstancia
} from "@puntaje/puntaje/api-services"
import { Observable } from "rxjs"
import { Asignatura, AsignaturaWithConfig, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AuthService, AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "unlocked-instrumento-cb-row",
    templateUrl: "./unlocked-instrumento-cb-row.component.html",
    styleUrls: ["./unlocked-instrumento-cb-row.component.scss"]
})
export class UnlockedInstrumentoCbRowComponent implements OnInit {
    @Input() evaluacionMultiple: EvaluacionMultiple
    @Input() evaluacionMultipleInstancia: EvaluacionMultipleInstancia
    @Input() evaluacionInstancias: EvaluacionInstancia[] = []
    plataforma: string
    porcentajePorAsignatura: any = {}
    evaluacionByAsignaturaId: { [id: number]: Evaluacion } = {}
    evaluacionInstanciaByAsignaturaId: { [id: number]: EvaluacionInstancia } = {}
    evaluacionMultipleDone: boolean = false
    hasBeenCompleted: boolean = false
    evaluaciones: Evaluacion[]
    evaluacionTipo: string
    puntajeTotal: number
    listaAsignaturas: Asignatura[]

    loading: boolean = true
    @Input() locked: boolean = null

    estadisticaClasificacionesByAsignaturaId: { [asignaturaId: number]: any }

    asignaturasByEvaluacionTipo$: Observable<{ [tipoEvaluacion: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos
    ) {
        this.plataforma = config.plataforma.name
    }

    async ngOnInit() {
        this.loading = true

        const usuario = this.authService.getUserData()
        const usuarioId = usuario.id

        const evaluacionInstancias = this.evaluacionMultipleInstancia
            ? this.evaluacionMultipleInstancia.evaluacion_instancias
            : []
        this.evaluaciones = this.evaluacionMultiple.evaluaciones

        this.evaluacionByAsignaturaId = this.evaluaciones.reduce((acc, evaluacion) => {
            acc[evaluacion.instrumento.asignatura_id] = evaluacion

            return acc
        }, {})

        this.evaluacionInstanciaByAsignaturaId = this.evaluaciones.reduce((acc, evaluacion) => {
            acc[evaluacion.instrumento.asignatura_id] = this.evaluacionInstancias.find(
                evaluacionInstancia => evaluacionInstancia.evaluacion_id === evaluacion.id
            )

            return acc
        }, {})

        const filteredEvaluaciones = this.evaluaciones.filter(
            e => !evaluacionInstancias.find(ei => ei.evaluacion_id == e.id)
        )

        this.evaluacionMultipleDone = filteredEvaluaciones.length == 0

        this.evaluacionTipo = this.evaluacionMultipleInstancia?.evaluacion_instancias
            .map(ei => ei.evaluacion)
            .map(e => e.instrumento)
            .map(i => i.generador_instrumento)
            .map(gi => gi.tipo_instrumento)
            .map(ti => ti.tipo_instrumento)[0]

        this.listaAsignaturas = Object.values(this.evaluacionInstanciaByAsignaturaId).map(
            eid => eid?.evaluacion.instrumento.generador_instrumento.asignatura_plataforma.asignatura
        )
        this.getData()

        this.loading = false
    }

    async getData() {
        const clasificacionTipoNombre = config.evaluaciones[this.evaluacionTipo]?.clasificacionTipoResultados || "area"
        const clasificacionTipos = await this.clasificacionTiposService.where({
            clasificacion_tipo: { clasificacion_tipo: clasificacionTipoNombre }
        })
        const clasificacionTipo = clasificacionTipos[0]

        const params = this.evaluacionMultipleInstancia
            ? {
                  collection: "EstadisticaInstanciaClasificacion",
                  estadistica: {
                      evaluacion_instancia_id: this.evaluacionMultipleInstancia?.evaluacion_instancias?.map(ei => ei.id)
                  }
              }
            : {}

        const estadisticas = await this.estadisticasService.wherePost(params)
        if (estadisticas.length !== 0) {
            this.estadisticaClasificacionesByAsignaturaId = estadisticas
                .map(e => e.estadistica_clasificaciones)
                .flat()
                .filter(ec => ec.clasificacion.clasificacion_tipo_id == clasificacionTipo.id)
                .reduce((acc, ec) => {
                    acc[ec.clasificacion.asignatura_id] = acc[ec.clasificacion.asignatura_id] || []
                    let estadisticaClasificacion = acc[ec.clasificacion.asignatura_id].find(
                        eca => eca.clasificacion.id == ec.clasificacion.id
                    )

                    if (estadisticaClasificacion) {
                        estadisticaClasificacion.correctas += ec.correctas
                        estadisticaClasificacion.incorrectas += ec.incorrectas
                        estadisticaClasificacion.omitidas += ec.omitidas
                    } else {
                        acc[ec.clasificacion.asignatura_id].push(ec)

                        estadisticaClasificacion = ec
                    }

                    const total =
                        estadisticaClasificacion.correctas +
                        estadisticaClasificacion.incorrectas +
                        estadisticaClasificacion.omitidas
                    estadisticaClasificacion.porcentaje = ((1.0 * estadisticaClasificacion.correctas) / total) * 100

                    return acc
                }, {})
        }

        this.evaluacionInstancias.forEach(ei => {
            this.porcentajePorAsignatura[
                ei.evaluacion.instrumento.generador_instrumento.asignatura_plataforma.asignatura_id
            ] = (ei.correctas / (ei.evaluacion.instrumento.numero_preguntas || 1)) * 100
        })
    }

    getCalificacionSimulacroSaberPro(asignaturaId: number): number {
        if (!this.estadisticaClasificacionesByAsignaturaId || !this.listaAsignaturas) {
            return 0
        }
        const calificaciones = this.listaAsignaturas
            .map(asignatura => {
                const cantClasificaciones = this.estadisticaClasificacionesByAsignaturaId[asignatura.id].length || 0
                const porcentajeTotal =
                    this.estadisticaClasificacionesByAsignaturaId[asignatura.id]
                        ?.map(ec => ec.porcentaje)
                        .reduce((a, b) => a + b, 0) || 0

                return cantClasificaciones > 0 ? ((porcentajeTotal / cantClasificaciones) * 300) / 100 : 0
            })
            .filter(value => value > 0)

        if (calificaciones.length === 0) return 0

        const total = calificaciones.reduce((sum, value) => sum + value, 0)
        this.puntajeTotal = total / calificaciones.length

        const cantClasificaciones = this.estadisticaClasificacionesByAsignaturaId[asignaturaId]?.length || 0
        const porcentajeTotal =
            this.estadisticaClasificacionesByAsignaturaId[asignaturaId]
                ?.map(ec => ec.porcentaje)
                .reduce((a, b) => a + b, 0) || 0

        return cantClasificaciones > 0 ? ((porcentajeTotal / cantClasificaciones) * 300) / 100 : 0
    }
}
